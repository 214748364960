html,
body,
#root {
    height: 100%;
    margin: 0;
    padding: 0;
    user-select: none;
    background-color: rgb(210, 89, 90);
}

html {
    overflow: hidden;
}

body {
    overflow: auto;
}

@font-face {
    font-family: 'Besan';
    src: local('Besan'), url(./fonts/besan/BESAN.otf) format('opentype');
}

.simple_text{
    text-overflow: ellipsis;
    width:80%;
    overflow: hidden;
    display: block;

}